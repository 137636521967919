.file-page {

  .text-muted {
    color: #606872 !important;
  }

  .file-folder {
    margin: 0.375rem;
    padding: 0.75rem;
    border: 1px solid $border-color;
    box-shadow: none;
    cursor: pointer;

    button, button:focus, button:hover {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .file-folder.folder-selected {
    background-color: #D7F5DB;
    div button, div p {
      color: inherit !important;
    }
    .avatar-title.bg-light {
      background-color: inherit !important;
    }
  }
}