.new_secondary_email_row.row>div {
  margin-top: 0;
}

@media (min-width: 768px) {
  .new_secondary_email_row {
    margin-top: 27px;
  }
}

button.new_secondary_email_btn {
  float: none;
  margin-top: 28px;
  width: 100%;
  border-radius: 5px;
  background-color: #007248;
  border-color: #007248;
}

button.select_products_btn {
  box-shadow: none;
  border-radius: 5px;
  background-color: #007248;
  border-color: #007248;
}

span.select_products_count {
  color: #777777;
  align-self: center;
  font-size: 13px;
  margin-top: 2px;
  margin-left: 10px;


}

.secondary_email_select {
  height: 130px;
}

.delete_secondary_email {
  margin-left: 0;
  margin-top: 15px;

  button {
    border-radius: 5px;
    float: none;
    width: auto;
    margin-left: 0;
  }
}

.products {
  padding: 0 16px; 
  gap: 24px;

  .bordered {
    border: solid 1px #dddddd;
    border-radius: 4px;
    padding: 8px 12px;
    flex: 1;
    height: 500px;
    overflow-y: scroll;

    .search {
      border: none;
      border-bottom: 1px solid #b3b3b3; 
      border-radius: 0;
      margin-bottom: 20px;
    }
    
    .productName {    
      width: 180px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 8px;
    }
    
    .productNameInput {
      margin-right: 20px;

      label {
        font-family: 'Roboto', serif;
        font-size: 13px;
        color: #777777;
        font-weight: 500;
      }
    }

    .companyAlert {
      position: relative;
      top: 45%;
      text-align: center;
      font-size: 14px;
      font-weight: 300;
    }

  }
}

.productsModal {
  overflow-y: scroll !important;
  height: 50vh;

  .search {
    border: none;
    border-bottom: 1px solid #b3b3b3; 
    border-radius: 0;
    margin-bottom: 10px;
  }

}

.profiles-item {
  display: flex;
  margin: 0.75rem 0;

  .profiles-item-first {
    width: 150px;
  }
}