.certificate-page {
  margin-top: 12px;
  color: #000000;

  .certificate-filter {
    padding: 6px 0 12px 0;    
    background-color: #f2f2f2;
    font-size: 12px;    
    margin: 10px 0px 0px 0px;
    
    p.certificate-filter-title {
      width: auto;
      padding-right: 4px;
      padding-top: 6px;
      float: left;
    }

    div.col-9 {
      padding: 0;
    }

    button {
      margin: 6px 0 0 0;
      padding: 0 4px;
      font-size: 12px;  
      color: #000000;  

      span.certificate-filter-count {
        border-radius: 4px;
        padding: 2px 8px;
        margin-left: 3px;
        color: #ffffff;
      }
    }
  }
  
  table {
    th:nth-child(0), th:nth-child(1) {
      width: 1px !important;
    }
    th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(7) {
      width: 8vw;
      min-width: 100px !important;     
    }
  }
}