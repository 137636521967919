.fonado-page {
  .fonado-card {
    margin-top: 12px;
    color: #000000;
    margin-bottom: 12px !important;

    .fonado-card-body {
      padding: 0.5rem 1.5rem !important;

      .input-fonado-style {
        font-size: 14px;
      }
    }
  }
  .attendance-table {
    padding: 8px 6px;
    border-radius: 0px;
    color: #2e2e2e;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
  }

  .items-table {
    align-items: center;
    padding: 2px 0px;
  }

  .title-table {
    padding-left: 5px;
  }

  .cpf-input-search {
    margin-bottom: 0 !important;
  }

  .expand-button,
  .collapse-button {
    width: 20px;
    height: 20px;
    background-color: #004c30;
    padding: 0;
    color: #ffffff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    text-align: center;
    box-shadow: 0px 1px 5px rgba(89, 138, 120, 0.5);

    &:hover {
      background-color: darken(#004c30, 10%);
    }
  }

  .separator {
    border-top: 1px solid #c0c0c0;
    align-items: center;
  }

  .status-count {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;

    &.blue {
      color: #00bcd4;
    }

    &.orange {
      color: #ff9800;
    }

    &.purple {
      color: #9536f4;
    }

    &.red {
      color: #f44336;
    }

    &.green {
      color: #4caf50;
    }
  }

  .record-count {
    font-size: 12px;
    margin-left: 5px;
  }
}

.fonado-form-modal {
  .fonado-deadline {
    .fonado-deadline-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 26px;
      height: 30px;

      i {
        font-size: 20px;
      }
    }

    .fonado-deadline-div {
      position: absolute;
      margin-left: 30px;
      background-color: #ffffff;
      box-shadow: 0 0 4px 4px rgb(0 0 0 / 10%);
      margin-top: -304px;
      border-radius: 4px;
      padding: 15px;

      .fonado-deadline-div-close {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 20px;
        line-height: 1;
        cursor: pointer;
      }

      h5 {
        font-size: 14px;
        font-weight: 600;
        margin: 10px 0 16px 0;
      }

      label {
        color: #444444;
        font-size: 0.8rem;
        font-weight: 400;
      }

      input:not([type='radio']) {
        border: none;
        border-bottom: 1px solid #b3b3b3;
        border-radius: 0;
        margin-bottom: 14px !important;
      }

      .fonado-deadline-div-footer {
        border-top: solid 1px #b3b3b3;
        display: flex;
        padding-top: 14px;
        justify-content: space-between;
        align-items: flex-end;
        height: 55px;

        input {
          width: 100px !important;
          margin: 0 !important;
          padding: 0.15rem 0 0.15rem 0.6rem !important;
        }
      }
    }
  }
}

.btn_copypaste {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  padding: 0;
  border-radius: 5px;
}
