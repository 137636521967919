.credit_card-page {
  margin-top: 12px;
  color: #000000;

  .credit_card-filter {
    padding: 6px 0 12px 0;
    background-color: #f2f2f2;
    font-size: 12px;
    margin: 10px 0px 0px 0px;

    p.credit_card-filter-title {
      width: auto;
      padding-right: 4px;
      padding-top: 6px;
      float: left;
    }

    div.col-9 {
      padding: 0;
    }

    button {
      margin: 6px 0 0 0;
      padding: 0 4px;
      font-size: 12px;
      color: #000000;

      span.credit_card-filter-count {
        border-radius: 4px;
        padding: 2px 8px;
        margin-left: 3px;
        color: #ffffff;
      }
    }
  }

  &.credit_card-geral table {
    td:nth-child(1) {
      width: 60px !important;
      min-width: 60px !important;
      max-width: 78px !important;
    }
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(8) {
      width: 6vw;
    }
    th:nth-child(7) {
      width: 8vw;
    }
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(9) {
      width: auto;
    }
    th:nth-child(10) {
      width: 1px !important;
    }
    td:nth-child(0),
    th:nth-child(0),
    th:nth-child(1) {
      width: 1px !important;
    }
    //th:nth-child(4), th:nth-child(5) {
    //  background-color: #D7F5DB80 !important;
    //}

    td .sub-row-title {
      font-size: 11px;
    }

    tr.sub-row td:last-child {
      width: 50%;
    }

    tbody tr:not(.sub-row):hover {
      // background-color: $gray-200;
    }
  }
}
