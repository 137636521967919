.loans-page {
  margin-top: 12px;
  color: #000000;

  .loans-filter {
    padding: 6px 0 12px 0;    
    background-color: #f2f2f2;
    font-size: 12px;    
    margin: 10px 0px 0px 0px;

    p.loans-filter-title {
      width: auto;
      padding-right: 4px;
      padding-top: 6px;
      float: left;
    }

    div.col-9 {
      padding: 0;
    }

    button {
      margin: 6px 0 0 0;
      padding: 0 4px;
      font-size: 12px;  
      color: #000000;  

      span.loans-filter-count {
        border-radius: 4px;
        padding: 2px 8px;
        margin-left: 3px;
        color: #ffffff;
      }
    }

    .col-60 {
      flex: 0 0 60%; 
      max-width: 60%;
    }

    .col-40 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }

  &.loans-physic table {
    td:nth-child(0), td:nth-child(1) {
      width: 78px !important;
      min-width: 78px !important;
      max-width: 78px !important;
    }
    th:nth-child(0), th:nth-child(1), th:nth-child(8), th:nth-child(12) {
      width: 1px !important;
    }
    th:nth-child(10) {
      min-width: 100px;
    }
  }

  &.loans-geral table {
    th:nth-child(9), th:nth-child(10), th:nth-child(11) {
      width: 1px !important;
    }
    td:nth-child(0), td:nth-child(1) {
      width: 78px !important;
      min-width: 78px !important;
      max-width: 78px !important;
    }
    th:nth-child(7) {
      width: 20vw;
    }
    th:nth-child(8) {
      min-width: 100px;
    }
    th:nth-child(0), th:nth-child(1) {
      width: 1px !important;
    }
    //th:nth-child(4), th:nth-child(5) {
    //  background-color: #D7F5DB80 !important;
    //}

    td .sub-row-title {
      font-size: 11px;
    }

    tr.sub-row td:last-child {
      width: 50%;
    }

    tbody tr:not(.sub-row):hover {
      background-color: $gray-200;
    }
  }
}

.liveness-link-button {
  text-decoration: underline;
  color: $primary;
  cursor: pointer;
  opacity: 0.9;
  margin-bottom: 2px;
  font-size: 13px;

  &:hover {
    opacity: 1;
  }
}
