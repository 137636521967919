.schedule-content {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px 0 20px 0;
  border: 1px solid #dddddd;
  margin-bottom: 20px;

  .schedule-day {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;

    p {
      margin-bottom: 0;
    }

    .inputs {
      display: flex;
      align-items: center;
      gap: 10px;

      .each-input {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.schedule-footer {
  border-top: none;

  .btn-salvar-footer {
    display: flex;
    justify-content: flex-end;
  }
}

.select-site {
  width: 200px;
  margin-bottom: 20px;
}
