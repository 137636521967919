.holiday_box_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 25px 10px 25px;
  box-shadow: 0px 0px 5px 0px #dee2e6;
  background-color: white;
  gap: 10px;

  h4 {
    font-size: 15px;
    font-weight: 400;
  }

  .holiday_box_header {
    display: flex;
    justify-content: space-between;

    .holiday_box_header_name {
      width: 80%;
    }
  }

  .holiday_box_times_div {
    display: flex;
    gap: 30px;
    padding: 8px 30px 8px 30px;
    border: 1px solid #dee2e6;

    p {
      margin-bottom: 0;
    }

    .holiday_box_calendar_outside {
      width: 40%;
    }

    .holiday_box_calendar {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .holiday_box_input_script {
    border: 1px solid #dee2e6;
    padding: 10px;
  }

  .holiday_box_formInputs {
    display: flex;
    width: 100%;
    gap: 30px;
    height: 250px;
    justify-content: space-between;
    align-items: flex-end;

    .holiday_box_textArea {
      width: 60%;
      height: 100%;

      .holiday_box_inside_textAreas {
        border: 1px solid #dee2e6;
        padding: 20px;

        textarea {
          padding: 10px;
          width: 100%;
          resize: none;
          height: 150px;
          background-color: white;
          border-color: #dee2e6;
        }
      }
    }
  }
}
