.schedule_box_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  box-shadow: 0px 0px 5px 0px #dee2e6;
  margin: 50px 0px 80px 0px;
  gap: 20px;
  background-color: white;

  h4 {
    font-size: 15px;
    font-weight: 400;
  }

  .schedule_box_header {
    display: flex;
    justify-content: space-between;

    .schedule_box_retangule_image {
      position: absolute;
      background-image: linear-gradient(to right, #007a4c, #1f2b2d);
      margin-top: -67px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 200px;
    }

    .schedule_box_btn_save {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .schedule_box_times_div {
    display: flex;
    gap: 30px;
    padding: 15px 30px 20px 30px;
    border: 1px solid #dee2e6;

    .schedule_box_time_div {
      width: 80px;

      p {
        font-size: 14px;
        margin-bottom: 0;
      }

      .schedule_box_inside_time {
        display: flex;
        gap: 5px;
        flex-direction: column;

        span {
          color: #a0a0a0;
          font-size: 10px;
        }
      }
    }

    .input_hour_schedule_box {
      margin: 0px;
    }
  }

  .schedule_box_input_script {
    border: 1px solid #dee2e6;
    padding: 10px;
  }

  .schedule_box_formInputs {
    display: flex;
    width: 100%;
    gap: 30px;
    height: 250px;

    .schedule_box_textArea {
      width: 50%;
      height: 100%;

      .schedule_box_inside_textAreas {
        border: 1px solid #dee2e6;
        padding: 20px;

        textarea {
          padding: 10px;
          width: 100%;
          resize: none;
          height: 150px;
          background-color: white;
          border-color: #dee2e6;
        }
      }
    }

    .schedule_box_preview_icon {
      width: 30%;
      height: 100%;

      .schedule_box_inside_preview_icon {
        border: 1px solid #dee2e6;
        padding: 20px;
        height: 79%;

        .schedule_box_inside_preview_icon_img {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .schedule_box_icon {
      width: 38%;

      .schedule_box_inside_icon {
        border: 1px solid #dee2e6;
        height: 79%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
