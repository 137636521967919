.insurance-page {

  .insurance-item {
    margin: 0 10px;
    line-height: 1.3;

    .insurance-item-title {
      margin: 0;
      cursor: pointer;
    }

    .insurance-item-competency  {
      margin-top: 24px;
      transition: width 1s;
      
      .col {
        height: 103px;
        padding-top: 20px;
      }

      b {
        color: $gray-700;
      }

      .insurance-competency-paid-text {
        color: $green-700;
      }
    }
  }

  .insurance-chart {
    width: 150px;
    height: 103px;
    float: left;
    text {
      fill: $body-color !important;
    }
  }
}